import {
  S3Client,
  PutObjectCommand,
  GetObjectCommand,
} from '@aws-sdk/client-s3'
import { v4 as uuid } from 'uuid'

import { useRuntimeConfig } from '#imports'

import type r4 from 'fhir/r4'

import { Version } from '@/utils/autoRendererVersions'
import type { MfxAutoRendererV1Config } from '@/utils/tempMfxAutoRendererV1Config.type'

export interface SaveData {
  key: string
  config: MfxAutoRendererV1Config
  sources: Partial<r4.Questionnaire>[]
  responses: Partial<r4.QuestionnaireResponse>[]
  version: Version
}

type SaveRequest = Omit<SaveData, 'key'>

const runtimeConfig = useRuntimeConfig()

const region = 'us-west-2'
const bucket = 'vtree-mfx-fiddle'

const s3 = new S3Client({
  region,
  credentials: {
    accessKeyId: runtimeConfig.public.aws.accessKeyId,
    secretAccessKey: runtimeConfig.public.aws.secretAccessKey,
  },
})

const isSaveData = (data: unknown): data is SaveData => {
  const typedData = data as SaveData

  return !!(
    typedData &&
    typedData.key &&
    Array.isArray(typedData.sources) &&
    Array.isArray(typedData.responses)
  )
}

const get = (key: string) => {
  return s3
    .send(
      new GetObjectCommand({
        Bucket: bucket,
        Key: key,
      })
    )
    .then(async (response) => {
      const responseString = await response?.Body?.transformToString()
      const responseObject = JSON.parse(responseString || '{}') as object

      if (isSaveData(responseObject)) {
        return responseObject
      }

      return undefined
    })
}

const save = (data: SaveRequest) => {
  const key = uuid()

  return s3
    .send(
      new PutObjectCommand({
        Bucket: bucket,
        Key: key,
        Body: JSON.stringify({ key, ...data }),
      })
    )
    .then((response) => {
      return {
        key,
        ...response,
      }
    })
}

export const useExternalStorage = () => {
  return { get, save }
}
